import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDXeyQSe4VMLS5H997v1N_0-UFugTQcg7M",
    libraries: "places",
    region: 'PT',
    language: 'pt',
  }
});
